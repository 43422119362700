<template>
  <nav class="flex h-full min-h-0 flex-col">
    
    <div class="flex-col border-b p-4 border-zinc-800 [&>[data-slot=section]+[data-slot=section]]:mt-2.5 hidden lg:flex h-[60px]">
      <div class="cursor-default flex w-full items-center gap-3 rounded-lg lg:mt-0 text-left text-base/6 font-medium text-white sm:text-sm/5">
        <!-- <span class="inline-grid shrink-0 align-middle [--avatar-radius:20%] [--ring-opacity:20%] *:col-start-1 *:row-start-1"> -->
          <AppLogoSquare v-if="optionsStore.sidebarCollapsed" />
          <AppLogo v-else />
           <!-- <LogoBig class="w-[130px] h-auto" /> -->
        <!-- </span> -->
      </div>
    </div>

    <div 
    class="flex flex-1 flex-col overflow-y-auto [&>[data-slot=section]+[data-slot=section]]:mt-8"
    :class="[ optionsStore.sidebarCollapsed ? 'p-4 lg:p-2' : 'p-2' ]"
    >
      <div data-slot="section" class="flex flex-col gap-1">
        <AppSidebarItem v-for="item in mainNav" :collapsed="optionsStore.sidebarCollapsed" :type="item.type" :to="item?.to" :label="item?.label" :icon="item?.icon" iconSize="large" :function="item?.function" :notifications="item?.notifications" />
      </div>
      <div v-if="links.length" data-slot="section" class="max-lg:hidden flex flex-col gap-1">
        <h3 class="nba mb-1 px-2 text-xs/6 font-medium text-zinc-400">Links</h3>
        <AppSidebarItem v-for="item in links" :type="item.type" :to="item?.to" :label="item?.label" :icon="item?.icon" :function="item?.function" :notifications="item?.notifications" />
      </div>
      <div aria-hidden="true" class="mt-8 flex-1 lg:hidden"></div>
      <div 
      data-slot="section" 
      class="flex flex-col gap-0.5 lg:hidden"
      :class="[ optionsStore.sidebarCollapsed ? 'opacity-100 lg:opacity-0' : 'opacity-100' ]"
      >
        <AppSidebarItem v-for="item in supportItems" :collapsed="optionsStore.sidebarCollapsed" :type="item.type" :to="item?.to" :label="item?.label" :icon="item?.icon" :function="item?.function" :notifications="item?.notifications" />
      </div>
    </div>

    <div 
    v-if="profile?.subscription_status !== 'active' || (profile.value?.current_period_end && profile.value?.current_period_end < new Date().getTime())"
    :class="[ optionsStore.sidebarCollapsed ? 'opacity-100 lg:opacity-0' : 'opacity-100' ]"
    class="mt-auto p-4 [@media_((max-height:539px)_and_(min-width:920px))]:hidden [@media_((max-height:449px)_and_(max-width:919px))]:hidden">
      <div class="rounded-md bg-white">
        <div class="flex flex-col gap-y-1.5 p-3 md:p-4">
          <h3 class="text-lg lg:text-2xl/8 font-bold tracking-tight text-center lg:text-left [@media(max-height:599px)]:text-center text-zinc-950">
            <span class="lg:hidden">Your subscription has expired</span>
            <span class="hidden lg:inline">Subscribe today</span>
          </h3>
          <p class="hidden lg:block [@media(max-height:599px)]:hidden text-sm text-muted-foreground">
            Unlock all features and start practicing today.
          </p>
        </div>
        <div class="p-2 pt-0 md:p-4 md:pt-0">
          <NuxtLink to="/join" class="btn btn--md btn--yellow inline-block text-center w-full">
            Play now
          </NuxtLink>
          <p class="hidden lg:block mt-3 text-center text-xs text-zinc-500">
            <NuxtLink to="/play/account/subscription" class="underline">View billing history</NuxtLink>
          </p>
        </div>
      </div>
    </div>

    <div 
    class="max-lg:hidden flex justify-center flex-col border-t border-zinc-800 [&>[data-slot=section]+[data-slot=section]]:mt-2.5"
    :class="[ optionsStore.sidebarCollapsed ? 'lg:p-1' : 'px-2 py-2' ]"
    >
      <Menu as="div" class="relative">
        <MenuButton 
        class="cursor-default flex w-full items-center gap-3 rounded-lg text-left text-base/6 font-medium px-2 sm:py-2 sm:text-sm/5 data-[slot=icon]:*:size-6 data-[slot=icon]:*:shrink-0 sm:data-[slot=icon]:*:size-5 data-[slot=icon]:last:*:ml-auto data-[slot=icon]:last:*:size-5 sm:data-[slot=icon]:last:*:size-4 data-[slot=avatar]:*:-m-0.5 data-[slot=avatar]:*:size-7 data-[slot=avatar]:*:[--ring-opacity:10%] sm:data-[slot=avatar]:*:size-6 text-white hover:bg-white/5 data-[slot=icon]:*:data-[hover]:fill-white data-[active]:bg-white/5 data-[slot=icon]:*:data-[active]:fill-white data-[slot=icon]:*:data-[current]:fill-white"
        ref="userMenuButton"
        >
          <span class="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden" aria-hidden="true"></span>
          <span class="flex min-w-0 items-center gap-3">
            <span data-slot="avatar" class="size-9 inline-grid shrink-0 align-middle [--avatar-radius:20%] [--ring-opacity:20%] *:col-start-1 *:row-start-1 outline outline-1 -outline-offset-1 outline-white/[--ring-opacity] rounded-[--avatar-radius] *:rounded-[--avatar-radius]">
              <Icon class="size-full" name="heroicons:user-circle-20-solid" />
            </span>
            <span 
            class="min-w-0"
            :class="[ optionsStore.sidebarCollapsed ? 'opacity-0 visibility-hidden' : 'opacity-100' ]"
            >
              <span class="block truncate text-sm/5 font-medium text-white">{{ profile?.first_name }}</span>
              <span class="block truncate text-xs/5 font-normal text-zinc-400">{{ profile?.email }}</span>
            </span>
          </span>
          <svg 
          :class="[ optionsStore.sidebarCollapsed ? 'opacity-0 visibility-hidden' : 'opacity-100' ]"
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" data-slot="icon">
            <path fill-rule="evenodd" d="M11.78 9.78a.75.75 0 0 1-1.06 0L8 7.06 5.28 9.78a.75.75 0 0 1-1.06-1.06l3.25-3.25a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06Z" clip-rule="evenodd" />
          </svg>
        </MenuButton>
        
        <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
          class="absolute z-10 bottom-16 min-w-64 z-1000 isolate w-max rounded-xl p-1 outline outline-1 outline-transparent focus:outline-none focus:ring-0 overflow-y-auto bg-zinc-900 shadow-lg ring-1 ring-zinc-950/10 ring-inset ring-white/10 grid grid-cols-[auto_1fr_1.5rem_0.5rem_auto] transition"
          >
            <template v-for="item in userNavItems">
              <AppSidebarItem 
              :type="item.type" 
              :to="item?.to" 
              :label="item?.label" 
              :icon="item?.icon" 
              :function="item?.function" 
              :notifications="item?.notifications"
              />
            </template>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </nav>
</template>

<script setup>
import { 
  Menu,
  MenuButton,
  MenuItems
} from '@headlessui/vue'


const user = useSupabaseUser()
const userStore = useUserStore()
const { profile } = storeToRefs(userStore)
const { notifications } = storeToRefs(userStore)
const { signOut } = useUser()
const optionsStore = useOptionsStore()

const route = useRoute()

const userMenuButton = ref(null)

const closeMenu = () => {
  if (userMenuButton.value?.$el.getAttribute('aria-expanded') === 'true') {
    userMenuButton.value.$el.click()
  }
}

watch(
  () => route.path, 
  () => {
    closeMenu()
  }
)

const userSignout = async () => {
  closeMenu()
  await signOut()
}

const userNavItems = [
  {
    type: 'link',
    to: '/play/account',
    label: 'Account settings',
    icon: 'heroicons:user-circle-20-solid',
  },
  {
    type: 'link',
    to: '/play/account/subscription',
    label: 'Subscription',
    icon: 'heroicons:receipt-refund-20-solid',
  },
  {
    type: 'separator',
  },
  {
    type: 'link',
    to: '/support',
    label: 'Support',
    icon: 'heroicons:chat-bubble-oval-left-ellipsis-20-solid',
  },
  {
    type: 'separator',
  },
  {
    type: 'button',
    label: 'Sign out',
    icon: 'heroicons:arrow-right-on-rectangle-20-solid',
    function: userSignout,
  }
]

const mainNav = computed(() => [
  {
    type: 'link',
    to: '/play',
    label: 'Dashboard',
    icon: 'heroicons:home-20-solid',
  },
  {
    type: 'link',
    to: '/play/collections',
    label: 'Collections',
    icon: 'heroicons:view-columns-20-solid',
  },
  {
    type: 'link',
    to: '/play/explore',
    label: 'Explore',
    icon: 'heroicons:map-20-solid',
  },
  {
    type: 'link',
    to: '/play/favorites',
    label: 'Favorites',
    icon: 'heroicons:heart-20-solid',
  },
  {
    type: 'link',
    to: '/play/notifications',
    label: 'Notifications',
    icon: 'heroicons:bell-20-solid',
    notifications: notifications.value
  }
])

const links = [
  // {
  //   type: 'link',
  //   to: '/',
  //   label: 'Dashboard'
  // },
  // {
  //   type: 'link',
  //   to: '/',
  //   label: 'Explore'
  // },
  // {
  //   type: 'link',
  //   to: '/',
  //   label: 'Favorites'
  // }
]

const supportItems = [
  {
    type: 'link',
    to: '/contact',
    label: 'Contact',
    icon: 'heroicons:chat-bubble-oval-left-20-solid',
  }
]

</script>